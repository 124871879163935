.content {
  width: 70%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  overflow: visible
}

.staging {
  overflow: visible
}

.wrapper_content {
  min-height: 90%
}



.sidebar_left {
  width: 25%;
  border-left: none;
  border-bottom: none;
  border-right: none;
}

.sidebar_right {
  width: 5%;
  border-left: none;
  border-bottom: none;
  border-right: none;
}

.sidebar_wrapper, .sidebar_wrapper_inner {
  margin: 20px 5%;
  width: 90%;
}

.sidebar_element, .nav_element {
  padding: 0px;
  line-height: 25px;
  width: 100%;
  float: left;
}

.nav_wrapper, .nav_wrapper_inner {
  margin: 20px auto;
  width: 60px;
}

.sidebar_button {
  padding: 0px;
  line-height: 35px;
  width: 90%;
  height: 35px;
  margin: 15px 5%;
  vertical-align: middle;
  float: left;


}

.sidebar_button:hover {
  margin: 15px 5%;
  border: none;
  background-color: #F8B67C;
  vertical-align: middle;
}


.sidebar_button.aktive {
  background-color: #ed6f01;
  padding: 0px;
  line-height: 35px;
  width: 90%;
  height: 35px;
  margin: 15px 5%;
  vertical-align: middle;
  border: 1px #ed6f01 solid;
  color: white;
  float: left;
}

.opacity {
  height: 100%;
  float: left;
}


.opacity-wrapper {
  height: auto;
  float: left;
}

.staging li {
  width: 98%;
}

.staging li div {
  width: 100%;
}